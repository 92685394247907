import React, { useState, useEffect } from 'react';
import { Grid, Divider, Container, Typography, Box, Button, Stepper, Step, StepLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import FormSection from '../components/FormSection';
import TipSection from '../components/TipSection';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar } from '../contexts/SnackbarContext';

const FormPage = ({ isDataModified, setIsDataModified }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const component = location.state.context;
  const [questions, setQuestions] = useState([]);
  const [formValues, setFormValues] = useState({});
  const accountingYearId = localStorage.getItem('accountingYear');
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/areas?component_id=' + component.id, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => setSteps(data))
      .catch(error => {
        console.error('Error:', error)
        return <div>Error: {error}</div>;
      }
    );
  }, [component.id]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/questions?component_id=' + component.id + '&accounting_year_id=' + accountingYearId, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => response.json())
      .then(data => setQuestions(data))
      .catch(error => {
        console.error('Error:', error)
        return <div>Error: {error}</div>;
      }
    );
  }, [component.id, accountingYearId]);

  const handleNext = (isCompleted = true) => {
    setLoading(true);

    // Scroll to top of page
    window.scrollTo(0, 0);

    // Gather form data
    const formData = new FormData(document.querySelector('form'));
    const formObject = Object.fromEntries(formData.entries());
  
    // Merge with existing form values and make API call if it's the last step
    setFormValues(prevFormValues => {
      const updatedFormValues = {
        ...prevFormValues,
        ...formObject,
      };
  
      if (isCompleted === true && activeStep === steps.length - 1 && Object.values(updatedFormValues).some(value => value === '')) {
        // Get empty fields.
        const emptyFields = Object.entries(updatedFormValues)
          .filter(([key, value]) => value === '')
          .map(([key]) => key);
        
        setEmptyFields(emptyFields);
        setOpenDialog(true);
        setLoading(false);
      } else if (
        (
          isCompleted === true 
          && activeStep === steps.length - 1 
          && Object.values(updatedFormValues).every(value => value !== '')
        ) || (
          isCompleted === false
          && activeStep === steps.length - 1
        )
      ) {
        fetch(process.env.REACT_APP_API_URL + '/questions/answer?accounting_year_id=' + accountingYearId + '&component_id=' + component.id + '&is_completed=' + isCompleted, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(updatedFormValues),
        })
        .then(response => response.json())
        .then(data => {
          setIsDataModified(false);
          if (isCompleted) {
            showSnackbar('Dine svar er gemt og godkendt', 'success');
            navigate('/dashboard');
          } else {
            showSnackbar('Dine svar er gemt til senere', 'info');
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          setLoading(false);
        });
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoading(false);
      }
  
      return updatedFormValues;
    });    
  };

  const handleBack = () => {
    // Gather form data
    const formData = new FormData(document.querySelector('form'));
    const formObject = Object.fromEntries(formData.entries());
  
    setFormValues(prevFormValues => {
      const updatedFormValues = {
        ...prevFormValues,
        ...formObject,
      };

      return updatedFormValues;
    });

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = () => {
    setOpenDialog(false);

    // Foreach empty field, set the value to 0 on formValues.
    let newFormValues = {};
    emptyFields.forEach(field => {
      newFormValues[field] = 0;
      const input = document.querySelector(`input[name="${field}"]`);
      if (input) {
        input.value = 0;
      }
    });

    setFormValues(prevFormValues => {
      const updatedFormValues = {
        ...prevFormValues,
        ...newFormValues,
      };

      return updatedFormValues;
    });

    handleNext(true);
  };

  const fieldsByArea = emptyFields.reduce((groups, field) => {
    const questionId = field.split('_')[1]; // Extract the id from the field name
    const question = questions.find(q => parseInt(q.id) === parseInt(questionId)); // Find the question by id
    const areaId = question ? question.area_id : 'unknown'; // Get the area id, or use 'unknown' if the question is not found

    if (!groups[areaId]) {
      groups[areaId] = []; // Initialize the array for this area id if it doesn't exist yet
    }

    groups[areaId].push(question ? question.name : field); // Add the field to the array for this area id

    return groups;
  }, {});

  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom color="primary" sx={{ mb: 4 }}>
        { component.name.split(' - ')[1] }
      </Typography>
      <Box sx={{ overflowX: 'auto', mb: 4, display: { xs: 'none', md: 'block' } }}>
        <Stepper activeStep={activeStep} sx={{ minWidth: '600px' }}>
          {steps.map((step, index) => (
            <Step key={step.id} completed={activeStep > index}>
              <StepLabel>{step.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ flex: 1, mb: 3 }}>
            <FormSection questions={questions} activeStep={activeStep} steps={steps} formValues={formValues} isDataModified={isDataModified} setIsDataModified={setIsDataModified} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button variant="outlined" disabled={activeStep === 0 || loading} onClick={handleBack} sx={{ mr: 2 }}>
                Tilbage
              </Button>

              {activeStep === steps.length - 1 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleNext(false)}
                  sx={{ mr: 2, color: 'white' }}
                  disabled={loading}
                >
                  Gem til senere
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNext(true)}
                startIcon={activeStep === steps.length - 1 ? <CheckIcon /> : null}
                disabled={loading}
              >
                {activeStep === steps.length - 1 ? 'Godkend' : 'Næste'}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mx: 5, borderColor: '#88b3af', display: { xs: 'none', md: 'block' } }} />
        <Grid item xs={12} md={5}>
          <Box sx={{ flex: 1 }}>
            <TipSection steps={steps} activeStep={activeStep} />
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Godkend { component.name }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Du er ved at godkende dine indtastninger. Vi har fundet følgende tomme felter. Ved at godkende nulindberettes disse felter i rapporten. Det er muligt at genåbne og opdatere efterfølgende.
          </DialogContentText>
          <div>
            {Object.entries(fieldsByArea).map(([areaId, fields], index) => {
              const step = steps.find(s => parseInt(s.id) === parseInt(areaId)); // Find the step by area id
              const areaName = step ? step.name : 'unknown'; // Get the area name, or use 'unknown' if the step is not found
              return (
                <div key={index}>
                  <h3>{areaName}</h3>
                  <ul>
                    {fields.map((field, index) => (
                      <li key={index}>{field}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={loading} color="primary">
            Annuller
          </Button>
          <Button onClick={handleConfirmDialog} color="primary" disabled={loading} autoFocus>
            Godkend
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FormPage;