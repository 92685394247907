import React from 'react';
import { TextField, Grid, MenuItem, Select, Box, Typography, InputLabel, FormControl, InputAdornment } from '@mui/material';
import * as Icons from '@mui/icons-material';

const FormSection = ({ questions, activeStep, steps, formValues, isDataModified, setIsDataModified }) => {
  if (!questions || questions.length === 0 || 'error' in questions) return null;
  const area = steps[activeStep];
  if (!area || area.id === undefined) return null;
  const areaId = area.id;
  const areaQuestions = questions.filter(question => question.area_id == areaId); // eslint-disable-line eqeqeq
  const Icon = Icons[area.icon] || Icons.InfoOutlined;

  return (
    <form>
      <Box display="flex" alignItems="center">
        <Icon color="primary" />
        <Typography variant="h5" color="primary" style={{ padding: '10px' }}>
          {area.name}
        </Typography>

        <Box sx={{ marginLeft: 'auto', backgroundColor: 'primary.main', color: 'white', px: 2, py: 0.5, borderRadius: 2, display: { xs: 'block', sm: 'none' } }}>
          <Typography variant="body1">
            {activeStep + 1}/{steps.length}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 5 }}>
        {area.description}
      </Typography>
      {areaQuestions.map((question) => {
        const answeredQuestion = question.questions && question.questions[0] ? question.questions[0] : '';
        let answeredValue = '';
        let units = question.units;
        let defaultUnit = question.default_unit;

        // Get answered value from question, if it exists.
        if (answeredQuestion) {
          answeredValue = parseFloat(answeredQuestion.value);

          // TODO: When starting to work with multiple question_answer_values, this should be refactored, to use the value from question instead of question_answer_values.
          // Get question_answer_values from question, if it exists, and override answeredValue and defaultUnit.
          if (answeredQuestion.question_answer_values && answeredQuestion.question_answer_values[0]) {
            const questionAnswerValue = answeredQuestion.question_answer_values[0];
            answeredValue = parseFloat(questionAnswerValue.unit_value);
            defaultUnit = units.find(unit => unit.id === questionAnswerValue.unit_id) || defaultUnit;
          }
        }

        // Get answered value from formValues, if it exists (as this must be the most recent value).
        if (formValues[`value_${question.id}`]) {
          answeredValue = parseFloat(formValues[`value_${question.id}`]);
          defaultUnit = units.find(unit => unit.id === parseInt(formValues[`unit_${question.id}`])) || defaultUnit;
        }

        if (question.format === 'bool') {
          return (
            <Grid container spacing={2} key={question.id} sx={{ marginBottom: 3 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id={`label_${question.id}`}>{question.label}</InputLabel>
                  <Select
                    labelId={`label_${question.id}`}
                    label={question.label}
                    variant="outlined"
                    fullWidth
                    defaultValue={answeredValue}
                    name={`value_${question.id}`}
                    onChange={(e) => {
                      setIsDataModified(true);
                    }}
                  >
                    <MenuItem value={1}>Ja</MenuItem>
                    <MenuItem value={0}>Nej</MenuItem>
                  </Select>
                </FormControl>

                <input type="hidden" name={`unit_${question.id}`} value={defaultUnit.id} />
              </Grid>
            </Grid>
          );
        } else if (question.format === 'percent') {
          return (
            <Grid container spacing={2} key={question.id} sx={{ marginBottom: 3 }}>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label={question.label}
                  variant="outlined"
                  fullWidth
                  defaultValue={answeredValue}
                  name={`value_${question.id}`}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: { min: 0, max: 100 }
                  }}
                  onChange={(e) => {
                    setIsDataModified(true);
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  onBlur={(e) => {
                    if (e.target.value > 100) {
                      e.target.value = 100;
                    } else if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </Grid>

              <input type="hidden" name={`unit_${question.id}`} value={defaultUnit.id} />
            </Grid>
          );
        }

        // (Default) Format is float.
        if (units.length === 1) {
          return (
            <Grid container spacing={2} key={question.id} sx={{ marginBottom: 3 }}>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label={question.label}
                  variant="outlined"
                  fullWidth
                  defaultValue={answeredValue}
                  name={`value_${question.id}`}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{defaultUnit.label}</InputAdornment>,
                  }}
                  onChange={(e) => {
                    setIsDataModified(true);
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />  
              </Grid>
              <input type="hidden" name={`unit_${question.id}`} value={defaultUnit.id} />
            </Grid>
          );
        } else {
          return (
            <Grid container spacing={2} key={question.id} sx={{ marginBottom: 3 }}>
              <Grid item xs={8}>
                <TextField
                  type="number"
                  label={question.label}
                  variant="outlined"
                  fullWidth
                  defaultValue={answeredValue}
                  name={`value_${question.id}`}
                  onChange={(e) => {
                    setIsDataModified(true);
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  variant="outlined"
                  fullWidth
                  defaultValue={defaultUnit.id}
                  name={`unit_${question.id}`}
                  onChange={(e) => {
                    setIsDataModified(true);
                  }}
                >
                  {units.map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                      {unit.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          );
        }
      })}
    </form>
  );
};

export default FormSection;